import compose from 'just-compose'
import i18n from '@/i18n'

const t = i18n.global.t

const DEFAULT_COUNTRY = 'DE'

export const macrosDialogText = {
  STRING: t('pages.settings.auto_id_generator.string'),
  PADDING: t('pages.settings.auto_id_generator.padding')
}

const getDateData = (date = new Date()) => {
  const getYear = `${date.getFullYear()}`
  const getMonth = `${date.getMonth() + 1}`.padStart(2, '0')
  const getDay = `${date.getDate()}`.padStart(2, '0')
  const getFullDate = `${getDay}${getMonth}${`${getYear}`.slice(2)}`
  return { getYear, getMonth, getDay, getFullDate }
}

export const getElements = ({
  date = new Date(),
  additionalData = {}
} = {}) => {
  const { country = DEFAULT_COUNTRY, branch = 1234 } = additionalData
  const { getFullDate, getMonth, getYear, getDay } = getDateData(date)
  return [
    {
      name: 'sequence',
      label: t('pages.settings.auto_id_generator.element.sequence'),
      template: 'SEQUENCE',
      macro: 'PADDING',
      value: '123',
      promptUser: true,
      oneTimeUse: true,
      required: true
    },
    {
      name: 'full_date',
      label: t('pages.settings.auto_id_generator.element.full_date'),
      template: 'FULL_DATE',
      value: getFullDate,
      oneTimeUse: true
    },
    {
      name: 'year',
      label: t('pages.settings.auto_id_generator.element.year'),
      template: 'YEAR',
      value: getYear,
      oneTimeUse: true
    },
    {
      name: 'month',
      label: t('pages.settings.auto_id_generator.element.month'),
      template: 'MONTH',
      value: getMonth,
      oneTimeUse: true
    },
    {
      name: 'day',
      label: t('pages.settings.auto_id_generator.element.day'),
      template: 'DAY',
      value: getDay,
      oneTimeUse: true
    },
    {
      name: 'country',
      label: t('pages.settings.auto_id_generator.element.country'),
      template: 'country',
      value: country,
      oneTimeUse: true
    },
    {
      name: 'branch',
      label: t('pages.settings.auto_id_generator.element.branch'),
      template: 'branch',
      value: branch,
      oneTimeUse: true
    },
    {
      name: 'text',
      label: t('pages.settings.auto_id_generator.element.text'),
      template: 'STRING',
      value: '',
      macro: 'STRING',
      isTextElement: true,
      promptUser: true
    },
    {
      name: 'dash',
      label: t('pages.settings.auto_id_generator.element.dash'),
      template: '-',
      value: ' - '
    },
    {
      name: 'product_group_number',
      label: t('pages.settings.auto_id_generator.element.product_group_number'),
      template: 'product_group_number',
      value: '3333',
      oneTimeUse: true
    }
  ]
}

export const generateMacroTemplate = {
  STRING: ({ val }) => `STRING("${val}")`,
  PADDING: ({ val, template, char, max }) =>
    [template, val, char, max].filter((i) => i !== '').join(',')
}

const parseTemplateRegex = /(?:(\{))([a-zA-Z10-9()'",_-]+?)(?:(\}))/g
const parseSingleTemplateElement = /^\{([a-zA-Z10-9()'",_-]+?)\}$/
const extractStringMacroParam = /STRING\([\\'"]+([\w]+)[\\'"]+\)/
const commaSeperated = /^[\w]+,["'\w\d,]+$/

/**
 * @param {string} template
 * @returns {string[]}
 */
export const parseToArr = (template) =>
  template
    ? template
        .match(parseTemplateRegex)
        .map((t) => t.match(parseSingleTemplateElement)[1])
    : []

/**
 * @param {string[]} templateArr
 * @returns {string[]}
 */
export const organiseToObject = (templateArr) =>
  templateArr ? templateArr.map((t) => ({ template: t })) : []

/**
 * @param {Object} additionalData
 * @param {Object[]} templateArr
 * @param {Object} [date]
 * @param {string} [dateNow]
 * @returns {Object}
 */
export const mergeWithElements = (additionalData) => (
  templateArr,
  date = new Date(),
  dateNow = date.getTime()
) =>
  templateArr.map((obj, i) => {
    const elem = getElements({ date, additionalData }).find(
      (e) => e.template === obj.template.match(/^[\w-]+/g)[0]
    )
    return { ...elem, ...obj, key: dateNow + i }
  })

/**
 * @param {Object[]} arr
 * @returns {Object[]}
 */
export const parseMacroElement = (arr) =>
  arr.map((e) => {
    if (e.macro === 'STRING' && extractStringMacroParam.test(e.template)) {
      const value = e.template.match(extractStringMacroParam)[1]
      return { ...e, name: value, value }
    }

    if (e.macro === 'PADDING' && commaSeperated.test(e.template)) {
      const paddingVals = e.template
        .substr(e.template.indexOf(',') + 1)
        .split(',')
      return { ...e, value: e.value.padStart(...paddingVals) }
    }

    return e
  })

/**
 * @param {string} initialTemplate
 * @returns {Object[] | null}
 */
export const parseTemplate = (template, additionalData) => {
  const templateArr = compose(
    parseToArr,
    organiseToObject,
    mergeWithElements(additionalData),
    parseMacroElement
  )(template)
  return templateArr.length ? templateArr : null
}
