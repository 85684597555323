<template>
  <div class="th-container">
    <vue-draggable
      v-model="filteredElements"
      class="elements"
      :move="onMove"
      :group="{ name: 'idGenerator', pull: 'clone', put: false }"
      item-key="name"
    >
      <template #item="{ element }">
        <element-block :element="element" />
      </template>
    </vue-draggable>
  </div>
</template>

<script>
import VueDraggable from 'vuedraggable'
import ElementBlock from './element-block'
import { getElements } from './utils'

import compare from 'just-compare'

export default {
  components: { VueDraggable, ElementBlock },
  props: {
    pickedElements: {
      type: Array,
      required: true
    },
    wantedElements: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      elements: getElements()
    }
  },
  computed: {
    filteredElements: {
      get() {
        return this.wantedElements.length
          ? this.elements.filter((e) => this.wantedElements.includes(e.name))
          : this.elements
      },
      set() {
        // vuedraggable expects a setter for the computed property (will give a runtime error otherwise)
        // but we don't want to apply setter changes on the elements container, so it's just an empty setter
      }
    }
  },
  watch: {
    pickedElements: function (newEls, oldEls) {
      if (!compare(newEls, oldEls)) {
        this.elements = this.updateElementsStatus()
      }
    }
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element
      return (
        (!relatedElement || !relatedElement.disabled) &&
        !draggedElement.disabled
      )
    },
    updateElementsStatus() {
      return this.elements.map((el) => ({
        ...el,
        disabled: this.pickedElements.includes(el.name) && el.oneTimeUse
      }))
    }
  }
}
</script>

<style scoped>
.th-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

.elements {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 0.3rem;
}
.sortable-ghost {
  opacity: 0.5;
}
</style>
