<template>
  <div class="template-container text-th-color-header">
    <div v-for="elem in computedElements" :key="elem.key">
      <span :class="[{ hovered: elem.hovered }]">
        {{ elem.value }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hovered: {
      type: Object,
      default: () => ({})
    },
    elements: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    computedElements() {
      return this.elements.map((elem) => ({
        ...elem,
        hovered: elem.key === this.hovered.key
      }))
    }
  }
}
</script>

<style scoped>
.template-container {
  display: flex;
  justify-content: center;
}
.hovered {
  background-color: var(--primary-color-focus);
}
</style>
