<template>
  <div class="drop-zone-wrapper">
    <div class="drop-zone">
      <div v-if="!elements.length" class="placeholder">
        <span class="placeholder-text-wrapper">
          <span class="placeholder-text">
            <span class="md-screen-placeholder-text">
              {{
                $t(
                  'pages.settings.auto_id_generator.drop_zone_placeholder_text'
                )
              }}
            </span>
            <span class="bg-screen-placeholder-text-extra">
              {{
                $t(
                  'pages.settings.auto_id_generator.drop_zone_placeholder_text_extra'
                )
              }}
            </span>
          </span>
          <el-icon class="bg-screen-placeholder-icon"><Back /></el-icon>
        </span>
      </div>
      <vue-draggable
        v-model="elements"
        class="elements"
        :class="{ empty: elements.length === 0 }"
        group="idGenerator"
        item-key="key"
        @change="onAdded"
      >
        <template #item="{ element }">
          <div class="element-wrapper">
            <element-block :element="element" />
            <div class="remove" @click="removeElement(element)">
              <el-icon><CloseBold /></el-icon>
            </div>
          </div>
        </template>
      </vue-draggable>
      <button
        v-if="elements.length"
        class="absolute hidden md:block close-button"
        @click="resetTemplate"
      >
        <el-icon><Close /></el-icon>
      </button>
    </div>
    <template-dialog
      :dialog-title="dialogTitle"
      :on-cancel-dialog="onCancelDialog"
      :on-save-dialog="onSaveDialog"
      :update-dialog-input="updateDialogInput"
      :update-max-input="updateMaxInput"
      :update-padding-character="updatePaddingCharacter"
      :update-reset-input="updateResetInput"
      :should-reset-input="shouldResetInput"
      :dialog-type="dialogType"
      :show-max-input="showMaxInput"
      :max-input-label="maxInputLabel"
    />
  </div>
</template>

<script>
import VueDraggable from 'vuedraggable'
import safeGet from 'just-safe-get'
import ElementBlock from './element-block'
import TemplateDialog from './dialog'
import { generateMacroTemplate, macrosDialogText, parseTemplate } from './utils'

const initialData = {
  elementPromptedIndex: null,
  elementPrompted: {},
  shouldResetInput: false,
  dialogInput: '',
  maxInput: '',
  paddingCharacter: '',
  dialogTitle: '',
  dialogType: ''
}

const getInitialData = (extra) => ({ ...initialData, ...extra })

export default {
  components: { VueDraggable, ElementBlock, TemplateDialog },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    updatePickedElements: {
      required: true,
      type: Function
    },
    configPath: {
      required: true,
      type: String
    },
    showMaxInput: {
      type: Boolean,
      default: false
    },
    skipMaxInputIfEmpty: {
      type: Boolean,
      default: false
    },
    maxInputLabel: {
      type: String,
      default: 'max-characters',
      validator: (val) => ['max-characters', 'max'].includes(val)
    }
  },
  data() {
    return {
      ...initialData
    }
  },
  computed: {
    isCustomSettings() {
      return this.modelValue !== null
    },
    template: {
      get() {
        return this.isCustomSettings
          ? this.modelValue
          : safeGet(
              this.$store.state.Config.clientAccountConfiguration,
              this.configPath
            )
      },
      set(template) {
        this.isCustomSettings
          ? this.$emit('update:modelValue', template)
          : this.$store.dispatch('Config/setClientAccountConfigurationValue', {
              path: this.configPath,
              value: template || null
            })
      }
    },
    elements: {
      get() {
        const country = safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'franchise.country'
        )

        const additionalData = {
          country
        }

        const elements = this.template
          ? parseTemplate(this.template, additionalData)
          : []

        this.updatePickedElements(elements)
        return elements
      },
      set(newElements) {
        this.updatePickedElements(newElements)
        this.saveTemplate(newElements)
      }
    }
  },
  methods: {
    removeElement(element) {
      this.elements = this.elements.filter((e) => e.key !== element.key)
    },
    removeElementByIndex(index) {
      this.elements = this.elements.filter((e, i) => i !== index)
    },
    onAdded(event) {
      if (event.added && event.added.element) {
        const { element } = event.added
        if (!element.key) {
          element.key = new Date().getTime()
        }
        if (element.promptUser && element.macro) {
          this.$thModal.show('generator-dialog')
          this.elementPromptedIndex = event.added.newIndex
          this.elementPrompted = element
          this.dialogTitle = macrosDialogText[element.macro] || element.name
          this.dialogType =
            element.template === 'country' ? element.template : element.macro
        }
      }
    },
    updateDialogInput(input) {
      this.dialogInput = input
    },
    updateMaxInput(input) {
      this.maxInput = input
    },
    updatePaddingCharacter(input) {
      this.paddingCharacter = input
    },
    onCloseDialog() {
      this.$thModal.hide('generator-dialog')
      setTimeout(() => {
        Object.assign(this.$data, getInitialData({ shouldResetInput: true }))
      }, 300)
    },
    onCancelDialog() {
      this.removeElementByIndex(this.elementPromptedIndex)
      this.onCloseDialog()
    },
    onSaveDialog() {
      const { macro, template } = this.elementPrompted
      const generatedTemplate = generateMacroTemplate[macro]({
        val: this.dialogInput,
        template: template,
        char: this.paddingCharacter || 0,
        max: this.maxInput || (this.skipMaxInputIfEmpty ? '' : 0)
      })
      if (this.elementPrompted.macro === 'STRING') {
        this.elementPrompted.value = this.dialogInput
        this.elementPrompted.name = this.dialogInput
      }
      if (this.elementPrompted.macro === 'PADDING') {
        this.elementPrompted.value = this.elementPrompted.value.padStart(
          this.dialogInput,
          this.paddingCharacter || 0
        )
      }

      this.elementPrompted.template = generatedTemplate
      this.elements = this.elements.map((e, i) =>
        i === this.elementPromptedIndex ? this.elementPrompted : e
      )
      this.onCloseDialog()
    },
    updateResetInput(bool) {
      this.shouldResetInput = bool
    },
    resetTemplate() {
      this.elements = []
    },
    saveTemplate(elements = []) {
      this.template = elements.map((e) => `{${e.template}}`).join('')
    },
    hoveredIn(elemKey) {
      this.$emit('hovered', { key: elemKey })
    },
    hoveredOut() {
      this.$emit('hovered', {})
    }
  }
}
</script>

<style scoped>
.drop-zone-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.drop-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  margin: 20px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

.elements {
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 22px;
  min-width: 500px;
  grid-gap: 0.3rem;
}

.elements.empty {
  min-height: 38px;
  border: 2px dashed var(--primary-color);
  border-radius: var(--border-radius);
}

.element-wrapper {
  display: flex;
  border-radius: var(--border-radius);
  position: relative;
}

.element-wrapper:hover .remove {
  display: block;
}

.remove {
  display: none;
  height: 18px;
  width: 18px;
  position: absolute;
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  right: -6px;
  top: -6px;
  font-size: 18px;
}

.sortable-ghost {
  opacity: 0.4;
}

.close-button {
  right: 2rem;
}

.placeholder {
  border: 2px dashed var(--border-radius);
}
.placeholder-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  font-size: 14px;
}

.placeholder-text {
  padding-right: 1rem;
}

.md-screen-placeholder-text {
  display: none;
}

.el-icon-back {
  transform: rotate(180deg);
}

@media (max-width: 1250px) {
  .bg-screen-placeholder-text-extra {
    display: none;
  }
  .md-screen-placeholder-text {
    display: inline-block;
  }
  .elements {
    min-width: 250px;
  }
}

@media (max-width: 850px) {
  .elements {
    min-width: 50px;
  }
}
</style>
