<template>
  <el-form
    ref="auto-id-generator"
    class="customer-number-generator"
    :class="{
      'cursor-not-allowed th-grayscale': disabled
    }"
    :model="{ pickedElementsNames }"
    :rules="rules"
  >
    <elements-container
      :class="{
        'pointer-events-none': disabled
      }"
      :picked-elements="pickedElementsNames"
      :wanted-elements="wantedElements"
    />
    <el-form-item prop="autoIdGenerator">
      <template-drop-zone
        id="autoIdGenerator"
        v-model="template"
        :class="{
          'pointer-events-none': disabled
        }"
        :update-picked-elements="updatePickedElements"
        :config-path="configPath"
        :show-max-input="showMaxInput"
        :skip-max-input-if-empty="skipMaxInputIfEmpty"
        :max-input-label="maxInputLabel"
        @hovered="setHoveredElement"
      />
    </el-form-item>
    <template-display
      :class="{
        'pointer-events-none': disabled
      }"
      class="template"
      :elements="pickedElements"
      :hovered="hoveredElement"
    />
  </el-form>
</template>

<script>
import compare from 'just-compare'
import { getElements } from './utils'
import ElementsContainer from './elements-container'
import TemplateDropZone from './template-drop-zone'
import TemplateDisplay from './template-display'

export default {
  name: 'AutoIdGenerator',
  components: {
    ElementsContainer,
    TemplateDropZone,
    TemplateDisplay
  },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    configPath: {
      required: true,
      type: String
    },
    wantedElements: {
      required: false,
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showMaxInput: {
      type: Boolean,
      default: false
    },
    skipMaxInputIfEmpty: {
      type: Boolean,
      default: false
    },
    maxInputLabel: {
      type: String,
      default: 'max-characters',
      validator: (val) => ['max-characters', 'max'].includes(val)
    }
  },
  data() {
    return {
      pickedElements: [],
      hoveredElement: {},
      rules: {
        autoIdGenerator: [
          {
            validator: this.validatePickedElements
          }
        ]
      }
    }
  },
  computed: {
    template: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    pickedElementsNames() {
      return this.pickedElements.map((x) => x.name)
    },
    requiredKeys() {
      return getElements().filter(({ required }) => required)
    }
  },
  watch: {
    pickedElements(newValue, oldValues) {
      if (!compare(newValue, oldValues)) {
        this.validate()
      }
    }
  },
  methods: {
    updatePickedElements(els) {
      this.pickedElements = els
    },
    setHoveredElement(element) {
      this.hoveredElement = element
    },
    validatePickedElements(rule, value, callback) {
      //form is disabled. no need to validate
      if (this.disabled) {
        return callback()
      }

      const missingRequiredKeys = this.requiredKeys.filter(
        ({ name }) => !this.pickedElementsNames.includes(name)
      )

      if (!missingRequiredKeys.length) {
        callback()
      } else {
        const errorMessage = `${this.$t(
          'common.forms.rules.field_warnings.required'
        )}: ${missingRequiredKeys.map(({ label }) => label).join(', ')}`
        callback(new Error(errorMessage))
      }
    },
    validate() {
      return new Promise((resolve) => {
        if (this.$refs['auto-id-generator'])
          this.$refs['auto-id-generator'].validate(resolve)
        else return true
      })
    }
  }
}
</script>

<style scoped>
.customer-number-generator {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 20px;
}

.th-container {
  border-color: var(--border-color);
  width: 100%;
}
.template {
  min-height: 25px;
  font-size: 20px;
}

.th-grayscale {
  filter: grayscale(1);
}
</style>
