<template>
  <div
    :class="[
      'element',
      {
        disabled: element.disabled,
        required: element.required,
        'unzer-element': isWhiteLabel
      }
    ]"
    v-text="elementTitle"
  />
</template>

<script>
export default {
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  computed: {
    elementTitle() {
      const { isTextElement, name, label } = this.element
      return isTextElement ? name : label
    }
  }
}
</script>

<style scoped>
.element {
  display: flex;
  justify-content: center;
  background-color: var(--selector-chip-background);
  border: var(--selector-chip-border);
  color: var(--primary-color);
  min-width: 50px;
  cursor: pointer;
  border-radius: var(--border-radius);
  font-size: var(--selector-chip-font-size);
  padding: 3px 12px;
  line-height: var(--selector-chip-line-height);
  letter-spacing: var(--selector-chip-spacing);
}
.required {
  background-color: var(--b-warning-bg-plain);
  border-color: var(--b-warning-bc-plain);
  color: var(--info-color);
}
.disabled,
.disabled.required {
  border-color: var(--selector-chip-disabled-border-color);
  background-color: var(--selector-chip-disabled-bg-color);
  color: var(--selector-chip-disabled-color);
  cursor: not-allowed;
}
</style>
