<template>
  <th-modal
    :title="dialogTitle"
    name="generator-dialog"
    width="500px"
    @close="onCancelDialog"
  >
    <el-form>
      <template v-if="dialogType === 'PADDING'" class="multi-input">
        <!-- Padding size -->
        <el-form-item
          :label="$t('pages.settings.auto_id_generator.padding_size')"
        >
          <el-input v-model="dialogInput" autofocus type="number" />
        </el-form-item>

        <!-- max size -->
        <el-form-item :label="maxInputLabelText">
          <el-input v-model="maxInput" type="number" />
        </el-form-item>

        <!-- Padding character -->
        <el-form-item
          v-if="showMaxInput"
          :label="$t('pages.settings.auto_id_generator.padding_character')"
          class="mb-0"
        >
          <el-input v-model="paddingCharacter" :maxlength="1" />
        </el-form-item>
      </template>

      <!-- Input -->
      <el-form-item
        v-else
        :label="$t('pages.settings.auto_id_generator.element.text')"
        class="mb-0"
      >
        <el-input v-model="dialogInput" autofocus />
      </el-form-item>
    </el-form>

    <template #footer>
      <span>
        <!-- Close -->
        <el-button @click="onCancelDialog">
          {{ $t('common.interactions.buttons.close') }}
        </el-button>

        <!-- Save -->
        <el-button
          type="primary"
          :disabled="!dialogInput.length"
          @click="onSaveDialog(dialogInput)"
        >
          {{ $t('pages.settings.auto_id_generator.save') }}
        </el-button>
      </span>
    </template>
  </th-modal>
</template>

<script>
export default {
  props: {
    dialogTitle: {
      required: true,
      type: String
    },
    dialogType: {
      required: true,
      type: String
    },
    onCancelDialog: {
      required: true,
      type: Function
    },
    onSaveDialog: {
      required: true,
      type: Function
    },
    updateDialogInput: {
      required: true,
      type: Function
    },
    updateMaxInput: {
      required: true,
      type: Function
    },
    updatePaddingCharacter: {
      required: true,
      type: Function
    },
    shouldResetInput: {
      type: Boolean,
      default: false
    },
    updateResetInput: {
      required: true,
      type: Function
    },
    showMaxInput: {
      type: Boolean,
      default: false
    },
    maxInputLabel: {
      type: String,
      default: 'max-characters',
      validator: (val) => ['max-characters', 'max'].includes(val)
    }
  },
  data() {
    return {
      dialogInput: '',
      paddingCharacter: '',
      maxInput: ''
    }
  },
  computed: {
    maxInputLabelText() {
      if (this.maxInputLabel === 'max') {
        return this.$t('pages.settings.auto_id_generator.element.max')
      }
      return this.$t('pages.settings.auto_id_generator.element.max_characters')
    }
  },
  watch: {
    dialogInput: function (newInput) {
      this.updateDialogInput(newInput)
    },
    maxInput: function (newInput) {
      this.updateMaxInput(newInput)
    },
    paddingCharacter: function (newInput) {
      this.updatePaddingCharacter(newInput)
    },
    shouldResetInput: function (reset) {
      if (reset) {
        this.dialogInput = ''
        this.paddingCharacter = ''
        this.maxInput = ''
        this.updateResetInput(false)
      }
    }
  }
}
</script>
